import '../scss/style.scss';

/* Custom JS General UI */
import './menu-toggle.js';
import './dropdown-toggle.js';

/* Bootstrap Plugins */
import './vendor/bootstrap/modal.js';
import './vendor/bootstrap/carousel.js';
import './vendor/bootstrap/tab.js';

/* Custom JS Modules */
import './hide-reveal.js';
import './switcher.js';
import './video.js';
import './filter.js';
import './forms.js';

const debounce = ( callback, wait ) => {
    let timeoutId = null;
    return ( ...args ) => {
        window.clearTimeout( timeoutId );
        timeoutId = window.setTimeout( () => {
            callback.apply( null, args );
        }, wait );
    };
}

window.onload = (event) => {
    document.documentElement.classList.add('js');
};

/**
 * Mark Element HTML on Scroll
 */
const scrolledBody = (amount) => {
    const html = document.documentElement;
    if ( html.scrollTop >= amount ) {
        html.classList.add( 'is-scrolled' );
    } else {
        html.classList.remove( 'is-scrolled' );
    }
}

const handleWindowScroll = debounce( ( ev ) => {
    scrolledBody(50);
}, 100 );

window.addEventListener( 'scroll', () => {
    handleWindowScroll();
}, false);


/**
 * Prevent JS Hash from polluting Location URL
 */
const preventLocationHash = (event) => {
    if( event.target.parentNode.href && event.target.parentNode.href.endsWith('#') ) {
        event.preventDefault();
    }
}
document.addEventListener('click', preventLocationHash);
