/**
 * Conditional Logic for Forms
 */

let formInvalid = false;
let formInvalidElems = document.querySelectorAll('.formbuilder-row .required +  ul');
const formInvalidElemsArray = Array.from(formInvalidElems);
formInvalid = (formInvalidElemsArray.length === 0) ? false : true;

const formID =  document.querySelector('[id^="formbuilder_"]').id;
const formOptions = document.getElementsByName(formID+'[request][0][type]');
const kitDataElem = document.getElementById(formID+'_KitData');
const personalDataElem = document.getElementById(formID+'_personalData');
const messageElem = document.getElementById(formID+'_Message');
const legalElem = document.getElementById(formID+'_legal');
const submitElem = document.getElementById(formID+'_submitThis');
const showHide = [];
showHide.push(personalDataElem, messageElem, legalElem, submitElem);

const hideElements = (array) => {
    array.forEach(elm => {
        elm.classList.remove('transition-unhidden');
        elm.classList.add('transition-hidden');
    });
}

const showElements = (array) => {
    array.forEach(elm => {
        elm.classList.remove('transition-hidden');
        elm.classList.add('transition-unhidden');
    });
}

const initForm = () => {

    if( true === formInvalid ) {
        formInvalidElems[0].parentNode.style["scroll-margin-top"] = "130px";
        formInvalidElems[0].parentNode.scrollIntoView();
        return;
    }

    hideElements(showHide);
    kitDataElem.classList.remove('transition-unhidden');
    kitDataElem.classList.add('transition-hidden');

    document.getElementById(formID+'_KitData_0_pressuresystem_0').checked = false; // yes not checked
    document.getElementById(formID+'_KitData_0_pressuresystem_1').checked = false; // no not checked
}

const handleChange = () => {
    formOptions.forEach(formOption => {
        if ( formOption.checked && formOption.value === 'kit_request' ) {
            showElements(showHide);
            kitDataElem.classList.remove('transition-hidden');
            kitDataElem.classList.add('transition-unhidden');
            document.getElementById(formID+'_KitData_0_pressuresystem_0').checked = false; // yes is false
            document.getElementById(formID+'_KitData_0_pressuresystem_1').checked = false; // no is true
        } 

        if ( formOption.checked && formOption.value === 'general_request' ) {
            showElements(showHide);
            kitDataElem.classList.remove('transition-unhidden');
            kitDataElem.classList.add('transition-hidden');
            document.getElementById(formID+'_KitData_0_pressuresystem_0').checked = false; // yes is false
            document.getElementById(formID+'_KitData_0_pressuresystem_1').checked = true; // no is true
        } 
    });
}

addEventListener('load', (event) => {
    if (!formOptions) {
        return;
    }
    initForm();
});

formOptions.forEach(formOption => {
    formOption.addEventListener('change', event => {
        handleChange();
    });
});
